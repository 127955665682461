.logo {
  max-height: 80px;
  width: 100%;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: #9e9e9e33;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.627),
      rgba(255, 255, 255, 0.761)
    ),
    url(../../Assets/headerbkg.jpeg);
    background-size: cover;
    background-position: center;
}
.menu-container {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
}
.menu {
  font-size: 20px;
  font-weight: 600;
  color: #380594;
}
.menu:hover {
  background-color: #9e9e9e4f;
  padding: 5px;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .header-container {
    flex-direction: column;
    height: 140px;
  }
  .menu-container {
    width: 100%;
    padding: 10px;
    border-top: 1.5px solid #9e9e9e73;
  }
  .menu {
    font-size: 15px;
  }
}
