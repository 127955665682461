.footer-container {
  position: relative;
  left: 0;
  bottom: 0;
  background-color: #9e9e9e33;
  text-align: center;
  color: #380594;
  font-size: 30px;
  font-weight: 600;
  height: 50px;
  padding: 10px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.627),
    rgba(255, 255, 255, 0.761)
  ),
  url(../../Assets/headerbkg.jpeg);
  background-size: cover;
  background-position: center;
}
@media only screen and (max-width: 768px) {
  .footer-container {
    font-size: 15px;
    height: 30px;
  }
}
